<template>
  <div class="mt-5 px-xl-5">
    <div class="mt-3">
      <b-row>
        <h6 class="page-title pb-2 mt-3 text-uppercase">Alertas Enviados</h6>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <TableJoinAlertNotification />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TableJoinAlertNotification from "../alert-sent-status/TableJoinAlertNotification";

export default {
  name: "alerts-resident-agent",
  components: {
    TableJoinAlertNotification
  },
};
</script>
