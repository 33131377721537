<template>
  <div>
    <b-row class="justify-content-end mb-2">
      <b-col xl="3" md="3">
        <b-input-group>
          <label class="form-title mt-1 me-2">Desde:</label>
          <DatePicker v-model="startDate" />
        </b-input-group>
      </b-col>
      <b-col xl="3" md="3">
        <b-input-group>
          <label class="form-title mt-1 me-2">Hasta:</label>
          <DatePicker v-model="endDate" />
        </b-input-group>
      </b-col>
      <b-col xl="3" md="3">
        <select class="form-select form-select-sm rounded-0" v-model="status">
          <option :value="null" selected hidden>Estado</option>
          <option :value="null"></option>
          <option v-for="(item, i) in alertStatus" :key="i" :value="item.key">
            {{ $t(`alert.${item.label}`) }}
          </option>
        </select>
      </b-col>
    </b-row>

    <b-row class="justify-content-end mb-3">
      <b-col xl="2" md="2">
        <b-input-group class="mt-1 justify-content-end">
          <b-button class="main-btn" size="sm" @click="refreshTable">
            Aplicar filtro
          </b-button>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table
      ref="aSentStatusTable"
      responsive
      stacked="md"
      small
      hover
      select-mode="single"
      selectable
      show-empty
      empty-text="No hay registros que mostrar"
      :filter="filter"
      :busy.sync="isBusy"
      :fields="fields"
      :items="retrieveData"
      :current-page="currentPage"
      :per-page="take"
    >
      <template #cell(created)="data">
        {{ formattedDate(data.item.createdAt) }}
      </template>
      <template #cell(message)="data">
        <span v-if="data.item.notificationType === 'Dynamic'">
          {{ getDynamicType(data.item.dynamicType) }}. 
        </span>
        <span v-if="data.item.message == '' && data.item.notificationType === 'Automatic'">
          {{ getAutomaticType(data.item.automaticType) }}. 
        </span>
        <span>
          {{ data.item.message }}
        </span>
      </template>
      <template #cell(name)="data">
        {{ data.item.lawFirmName }}
      </template>
      <template #cell(cur)="data">
        {{ data.item.lawFirmCur }}
      </template>
      <template #cell(status)="data">
        {{ getAlertStatus(data.item.status) }}
      </template>
    </b-table>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="take"
          size="sm"
          class="my-0 justify-content-end"
        >
          <template #first-text>
            <span
              ><b-icon-chevron-double-left
                scale="1.3"
              ></b-icon-chevron-double-left
            ></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span
              ><b-icon-chevron-right scale="1.3"></b-icon-chevron-right
            ></span>
          </template>
          <template #last-text>
            <span
              ><b-icon-chevron-double-right
                scale="1.3"
              ></b-icon-chevron-double-right
            ></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatePicker from "@/components/widgets/DatePicker.vue";
import { alertAutomaticType } from "@/config/alertAutomaticType";
import { alertDynamicType } from "@/config/alertDynamicType";
import { alertStatus } from "@/config/alertStatus";
import { expirationPeriodTypes } from "@/config/expirationPeriodTypes";
import { RepositoryFactory } from "@/services/api";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      alertDynamicType: alertDynamicType,
      alertAutomaticType: alertAutomaticType,
      expirationPeriodTypes: expirationPeriodTypes,
      alertStatus: alertStatus,
      isBusy: false,
      fields: [
        { key: "created", label: "Enviado" },
        { key: "message", label: "Mensaje" },
        { key: "name", label: "Abogado/Firma" },
        { key: "cur", label: "CUR" },
        { key: "status", label: "Estado" },
      ],
      currentPage: 1,
      take: 10,
      rows: 0,
      filter: null,
      search: null,
      status: null,
      superintendence: null,
      startDate: null,
      endDate: null,
      superintendences: [],
    };
  },
  mounted() {
    this.getSuperintendences();
  },
  methods: {
    async getSuperintendences() {
      try {
        var response =
          await RepositoryFactory.alertRepository.getSuperintendences();
        this.superintendences = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async retrieveData(context) {
      try {
        this.isBusy = true;
        var payload = {
          page: context.currentPage,
          take: this.take,
          search: context.filter,
          status: this.status,
          startDate: this.startDate,
          endDate: this.endDate,
        };

        var response =
          await RepositoryFactory.alertRepository.getJoinSentStatus(payload);
        this.rows = response.data.total;
        var items = response.data.items;
        return items;
      } catch (error) {
        window.console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    filterTable() {
      this.currentPage = 1;
      this.filter = this.search;
    },
    refreshTable() {
      this.filterTable();
      this.$refs.aSentStatusTable.refresh();
    },
    formattedDate(date) {
      return date != null
        ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A")
        : "";
    },
    getAlertStatus(value) {
      const t = this.alertStatus.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    getDynamicType(value) {
      const t = this.alertDynamicType.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    getAutomaticType(value) {
      const t = this.alertAutomaticType.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
  },
};
</script>
